@import "~antd/dist/antd.less";

//    "antd": "^4.6.4",

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Footer-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: "center";
  padding-top: 6%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  height: 30px;
  margin: 20px;
}

/*ANTD LAYOUT*/

.ant-layout-header {
  color: #fff !important;
  background-color: #fff !important;
}

.site-content {
  margin-top: 100px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
}

.footer-content {
  padding-left: 26%;
  padding-right: 26%;
}

@media screen and (max-width: 990px) {
  .footer-content {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.footerImage {
  width: 20em;
}

@media screen and (max-width: 575px) {
  .footerImage {
    width: 15em;
  }
}

.deleteMinus {
  font-size: 20px;
  padding-top: 37px;
  padding-left: 10px;
  margin-right: 0px;
  margin-bottom: 0px;
  flex-direction: "row";
  align-items: "left";
  justify-content: "left";
  text-align: "left";
}

@media screen and (max-width: 575px) {
  .deleteMinus {
    font-size: 20px;
    padding-top: 45px;
    padding-left: 10px;
    margin-right: 0px;
    margin-bottom: 0px;
    flex-direction: "row";
    align-items: "left";
    justify-content: "left";
    text-align: "left";
  }
}

@media screen and (max-width: 575px) {
  .ant-input-number-handler-wrap {
    width: 0;
    margin: 0;
  }
}

.site-layout-background {
  background: #fff !important;
  padding-top: 100px;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 100px;
  min-height: 360px;
}

.form-layout-background {
  padding-left: 23%;
  padding-right: 23%;
}

@media screen and (max-width: 990px) {
  .form-layout-background {
    padding-left: 1%;
    padding-right: 1%;
  }
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  background-color: #fafafa;
  min-height: 150px;
  text-align: center;
  padding: 20px;
}

.steps-action {
  margin-top: 24px;
}

.ant-select-selection {
  background-color: #4f8fb3;
}

/*   .ant-select-item {
    color: red;
 } */

.ant-select-item-option-active {
  background-color: darkgray !important;
}
.ant-select-item-option-active:hover {
  background-color: darkgray !important;
  /*   font-weight: bold; */
}

.ant-select-item
  .ant-select-item-option
  .custom-option
  .ant-select-item-option-active {
  background-color: darkgray !important;
  /*   font-weight: bold; */
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: darkgray !important;
  /*   font-weight: bold; */
}
.ant-select-item-option-content:hover {
  background-color: darkgray !important;
  /*   font-weight: bold; */
}

h2.ant-typography,
.ant-typography h2 {
  margin-top: 40px;
  margin-bottom: 10px;
}

h3.ant-typography,
.ant-typography h3 {
  margin-top: 30px;
  margin-bottom: 20px;
}

h4.ant-typography,
.ant-typography h4 {
  margin-top: 30px;
  margin-bottom: 10px;
}

.testclass {
  width: auto;
  height: auto;
  max-width: 80px;
  word-break: break-all;
  padding: 5px;
  border: 1px solid black;
  margin-bottom: 5px;
  display: inline-flex;
}

/* .ant-form-item-label {
  white-space: pre-wrap !important;
} */

.wrap-label {
  .ant-form-item-label {
    overflow-wrap: break-word;
    white-space: pre-wrap !important;
    min-height: 50px;
  }
}

.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}

@primary-color: #858794;@primary-hover-color: #879194;@ant-layout-header-color: #858794;@heading-color: #858794;@text-color: #858794;@text-color-secondary: #858794;@body-background: #fff;@component-background: #fff;