/* .site-layout-background {
  padding-left: 5%;
  padding-right: 5%;
} */

.pdfEditorContainer {
  width: 1230px;
  margin: 20px auto;
  display: inline-block;
}

.pdfEditor {
  width: 600px !important;
  height: 775px !important;
  margin-right: 20px;
  display: inline-block;
}

iframe {
  border: 1px solid black;
  opacity: 1;
}
